


































import { defineComponent, toRefs, watch } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getStudyEvents, getStudyEventCount } from './api';
import VClamp from 'vue-clamp';
import dayjs from 'dayjs';

export default defineComponent({
  components: { VClamp },
  filters: {
    time: (v: string) => dayjs(v).format('YYYY.MM.DD ddd HH:mm'),
  },
  setup(_, { root }) {
    const { state } = useMainStore();
    const { result: events, refresh } = useApi((page?: number, size?: number) =>
      getStudyEvents(page || 1, size || 10),
    );

    const { result: total } = useApi(() => getStudyEventCount());

    return {
      events,
      ...toRefs(state),
      total,
      onPageChange: (page: number) => {
        refresh(page, 10);
      },
      toDetail: (id: number) => {
        root.$router.push({ name: 'StudyEvent', params: { id: id.toString() } });
      },
    };
  },
});
