
















import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import Video from './Video.vue';
import Resource from './Resource.vue';
import { TabPane } from 'element-ui';
import Event from './Event.vue';

export default defineComponent({
  components: { Video, Resource, Event },
  setup(_, { root }) {
    const { state } = useMainStore();

    return {
      current: root.$route.hash.replace('#', '') || 'video',
      onTabClick: (tab: TabPane) => {
        location.hash = tab.name;
      },
    };
  },
});
