





























































import { defineComponent, ref, toRefs } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getStudyVideos, getStudyVideosCount } from './api';
import VClamp from 'vue-clamp';

export default defineComponent({
  components: { VClamp },
  filters: {
    plain: (val: string) => {
      const arrEntities: Record<string, string> = {
        lt: '<',
        gt: '>',
        nbsp: ' ',
        amp: '&',
        quot: '"',
      };
      return (
        val.replace(/(<([^>]+)>)/gi, '').replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
          return arrEntities[t];
        }) || '无介绍'
      );
    },
  },
  setup(_, { root }) {
    const { state } = useMainStore();
    const { result: videos, refresh } = useApi((page?: number, size?: number) =>
      getStudyVideos(page || 1, size || 10),
    );

    const { result: total } = useApi(() => getStudyVideosCount());

    return {
      videos,
      ...toRefs(state),
      total,
      onPageChange: (page: number) => {
        refresh(page, 10);
      },
      toDetail: (id: number) => {
        root.$router.push({ name: 'StudyVideo', params: { id: id.toString() } });
      },
    };
  },
});
